import coen from "../../assets/images/team/coen.png";
import background from "../../assets/images/team/team-bg.jpg";
import anna from "../../assets/images/team/team-new/anna.jpg";
import bas from "../../assets/images/team/team-new/bas.jpg";
import ceesjan from "../../assets/images/team/team-new/ceesjan.jpg";
import denick from "../../assets/images/team/team-new/denick.jpeg";
import edison from "../../assets/images/team/team-new/edison.jpg";
import jacqueline from "../../assets/images/team/team-new/jacqueline.jpeg";
import jelmer from "../../assets/images/team/team-new/jelmer.jpg";
import jeroen from "../../assets/images/team/team-new/jeroen.jpg";
import joao from "../../assets/images/team/team-new/joao.jpg";
import joost from "../../assets/images/team/team-new/joost.jpg";
import justen from "../../assets/images/team/team-new/justen.jpeg";
import karolien from "../../assets/images/team/team-new/karolien.jpg";
import marco from "../../assets/images/team/team-new/marco.jpg";
import mindaugas from "../../assets/images/team/team-new/mindaugas.jpg";
import mykola from "../../assets/images/team/team-new/mykola.jpg";
import sander from "../../assets/images/team/team-new/sander.jpg";
import wiebe from "../../assets/images/team/team-new/wiebe.jpg";
import ying from "../../assets/images/team/team-new/yinghao.jpg";
import yoshi from "../../assets/images/team/team-new/yoshi.jpg";

export default function TeamSection({ backgroundImg = "", className }) {
  return (
    <section
      className={`team-section-two bgs-cover ${
        backgroundImg === "none" ? "" : "text-white"
      } pt-115 rpt-95 pb-90 rpb-70 ${className || ""} `}
      style={
        backgroundImg
          ? { backgroundImage: `${backgroundImg}` }
          : { backgroundImage: `url(${background})` }
      }
    >
      <div className="container">
        <div className="section-title text-center mb-55">
          <span className="sub-title">Team Members</span>
          <h2>Meet our dedicated team!</h2>
        </div>
        <div className="row justify-content-center">
          <div className="col-lg-3 col-sm-6">
            <div
              className={`team-member wow fadeInUp delay-0-2s style-two text-white`}
            >
              <div className="image">
                <img src={ying} alt="Team Member" />
              </div>
              <div className="member-designation">
                <h5>Ying Hao</h5>
                <span>CEO, Founder</span>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-sm-6">
            <div
              className={`team-member wow fadeInUp delay-0-2s style-two text-white`}
            >
              <div className="image">
                <img src={karolien} alt="Team Member" />
              </div>
              <div className="member-designation">
                <h5>Karolien</h5>
                <span>Administration</span>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-sm-6">
            <div
              className={`team-member wow fadeInUp delay-0-2s style-two text-white`}
            >
              <div className="image">
                <img src={jelmer} alt="Team Member" />
              </div>
              <div className="member-designation">
                <h5>Jelmer</h5>
                <span>Human Resources</span>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-sm-6">
            <div
              className={`team-member wow fadeInUp delay-0-2s style-two text-white`}
            >
              <div className="image">
                <img src={ceesjan} alt="Team Member" />
              </div>
              <div className="member-designation">
                <h5>Ceesjan</h5>
                <span>Senior Software Engineer, Sysop</span>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-sm-6">
            <div
              className={`team-member wow fadeInUp delay-0-2s style-two text-white`}
            >
              <div className="image">
                <img src={wiebe} alt="Team Member" />
              </div>
              <div className="member-designation">
                <h5>Wiebe</h5>
                <span>Senior Software Engineer, Sysop</span>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-sm-6">
            <div
              className={`team-member wow fadeInUp delay-0-2s style-two text-white`}
            >
              <div className="image">
                <img src={mindaugas} alt="Team Member" />
              </div>
              <div className="member-designation">
                <h5>Mindaugas</h5>
                <span>Senior Software Engineer</span>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-sm-6">
            <div
              className={`team-member wow fadeInUp delay-0-2s style-two text-white`}
            >
              <div className="image">
                <img src={anna} alt="Team Member" />
              </div>
              <div className="member-designation">
                <h5>Anna</h5>
                <span>Software Engineer</span>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-sm-6">
            <div
              className={`team-member wow fadeInUp delay-0-2s style-two text-white`}
            >
              <div className="image">
                <img src={yoshi} alt="Team Member" />
              </div>
              <div className="member-designation">
                <h5>Yoshi</h5>
                <span>Barketing executive</span>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-sm-6">
            <div
              className={`team-member wow fadeInUp delay-0-2s style-two text-white`}
            >
              <div className="image">
                <img src={edison} alt="Team Member" />
              </div>
              <div className="member-designation">
                <h5>Edison</h5>
                <span>Software Engineer</span>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-sm-6">
            <div
              className={`team-member wow fadeInUp delay-0-2s style-two text-white`}
            >
              <div className="image">
                <img src={joost} alt="Team Member" />
              </div>
              <div className="member-designation">
                <h5>Joost</h5>
                <span>Front-end Engineer</span>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-sm-6">
            <div
              className={`team-member wow fadeInUp delay-0-2s style-two text-white`}
            >
              <div className="image">
                <img src={bas} alt="Team Member" />
              </div>
              <div className="member-designation">
                <h5>Bas</h5>
                <span>Software Engineer</span>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-sm-6">
            <div
              className={`team-member wow fadeInUp delay-0-2s style-two text-white`}
            >
              <div className="image">
                <img src={sander} alt="Team Member" />
              </div>
              <div className="member-designation">
                <h5>Sander</h5>
                <span>Software Engineer</span>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-sm-6">
            <div
              className={`team-member wow fadeInUp delay-0-2s style-two text-white`}
            >
              <div className="image">
                <img src={jeroen} alt="Team Member" />
              </div>
              <div className="member-designation">
                <h5>Jeroen</h5>
                <span>Software Engineer</span>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-sm-6">
            <div
              className={`team-member wow fadeInUp delay-0-2s style-two text-white`}
            >
              <div className="image">
                <img src={mykola} alt="Team Member" />
              </div>
              <div className="member-designation">
                <h5>Mykola</h5>
                <span>Software Engineer</span>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-sm-6">
            <div
              className={`team-member wow fadeInUp delay-0-2s style-two text-white`}
            >
              <div className="image">
                <img src={coen} alt="Team Member" />
              </div>
              <div className="member-designation">
                <h5>Coen</h5>
                <span>Software Engineer</span>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-sm-6">
            <div
              className={`team-member wow fadeInUp delay-0-2s style-two text-white`}
            >
              <div className="image">
                <img src={marco} alt="Team Member" />
              </div>
              <div className="member-designation">
                <h5>Marco</h5>
                <span>Front-end Engineer</span>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-sm-6">
            <div
              className={`team-member wow fadeInUp delay-0-2s style-two text-white`}
            >
              <div className="image">
                <img src={jacqueline} alt="Team Member" />
              </div>
              <div className="member-designation">
                <h5>Jacqueline</h5>
                <span>Scrum Master / Business Analyst</span>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-sm-6">
            <div
              className={`team-member wow fadeInUp delay-0-2s style-two text-white`}
            >
              <div className="image">
                <img src={justen} alt="Team Member" />
              </div>
              <div className="member-designation">
                <h5>Justen</h5>
                <span>Product Owner</span>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-sm-6">
            <div
              className={`team-member wow fadeInUp delay-0-2s style-two text-white`}
            >
              <div className="image">
                <img src={joao} alt="Team Member" />
              </div>
              <div className="member-designation">
                <h5>João</h5>
                <span>Software Engineer</span>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-sm-6">
            <div
              className={`team-member wow fadeInUp delay-0-2s style-two text-white`}
            >
              <div className="image">
                <img src={denick} alt="Team Member" />
              </div>
              <div className="member-designation">
                <h5>Denick</h5>
                <span>Software Engineer</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
